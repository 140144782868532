import './App.css';
import 'animate.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';
import QuestionView from './QuestionView';
import LeaderBoardView from './LeaderBoardView';
const EventEmitter = require('events');

class QuizPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Questions: [],
            q_ptr: 0,
            gamestate: 'idle',
            show_chaloey: false,
            selectedChoiceIndx: null
        };

        const eventEmitter = new EventEmitter();
        this.eventEmitter = eventEmitter;
    }

    async componentDidMount() {
        await this.loadGameSession();
        this.tryLoadMyAnswer();
        setInterval(async () => {
            this.loadGameSession();
        }, 2000);

        if (!window.localStorage.getItem("username")) {
            window.location.href = "/";
        }

        this.loadQuestions();
    }


    async tryLoadMyAnswer() {
        const q = this.state.q_ptr + 1;
        let res_myanswer = await axios.get('/api/my-answer?username=' + this.getUsername() + "&question_id=" + q);
        let MyAnswer = res_myanswer.data || {};
        if (MyAnswer) {
            this.setState({
                selectedChoiceIndx: MyAnswer.choice
            })
        }
    }

    async loadGameSession() {

        return new Promise(async (resolve, reject) => {
            try {
                let res = await axios.get('/api/last-session');
                let GameSession = res.data;

                if (Number(GameSession.current_question) - 1 != Number(this.state.q_ptr)) {
                    this.eventEmitter.emit('questionChanged');
                }

                this.setState({
                    q_ptr: Number(GameSession.current_question) - 1,
                    gamestate: GameSession.gamestate,
                    show_chaloey: (GameSession.gamestate == 'chaloey')
                }, () => {
                    resolve();
                })
            } catch (ex) {
                reject(ex);
            }
        })

    }

    getUsername() {
        let usr = window.localStorage.getItem("username");

        return usr
    }

    async loadQuestions() {
        let res = await axios.get('/api/questions');
        let Questions = res.data;
        Questions = Questions.map((q) => {
            q.choices = [q.choice0, q.choice1, q.choice2, q.choice3]
            return q;
        })

        this.setState({
            Questions
        })
    }

    getCurrentQuestion() {
        return this.state.Questions[Number(this.state.q_ptr)]
    }

    onClick(i) {
        if (Number(this.state.q_ptr) >= this.state.Questions.length - 1) {
            return;
        }

        this.setState({
            selectedChoiceIndx: i
        })

        axios.post('/api/submit-answer', {
            username: this.getUsername(),
            question_id: this.state.q_ptr + 1,
            choice: i
        }).then((res) => {
            //wait for chaloey
        }).catch((err) => {
            alert("Unable to submit answer")
        });

    }

    render() {
        let ptr = Number(this.state.q_ptr);
        let Questions = this.state.Questions;

        if (this.state.Questions.length == 0) return <div>Loading..</div>;

        let show_chaloey = this.state.show_chaloey;
        let correct_choice = Questions[ptr].correct_choice;

        if (Questions.length == 0) {
            return "Loading Questions.."
        }

        let wrong = Questions[ptr].choices[this.state.selectedChoiceIndx] != Questions[ptr]['choice' + correct_choice];

        return <div className="App">
            <Header progress={Number(this.state.q_ptr) / (this.state.Questions.length - 1)} />


            {show_chaloey && <div className={'animate__fadeIn animate__animated chaloey ' + (
                wrong ? 'incorrect' : 'correct'
            )}>
                <div>
                    {!Questions[ptr].choices[this.state.selectedChoiceIndx] && <span>
                        หมดเวลาคิด! กรุณารอคำถามถัดไป
                    </span>}
                    <span hidden={!Questions[ptr].choices[this.state.selectedChoiceIndx]} className='hxch'>{wrong ? "ผิด!" : "ถูกต้อง!"}</span>
                    <br />
                    {/* {Questions[ptr].choices[this.state.selectedChoiceIndx] && <span>
                        You answered "{Questions[ptr].choices[this.state.selectedChoiceIndx]}"
                    </span>} */}
                    <h3>
                        <small style={{ color: 'black' }}>คำตอบที่ถูกต้องคือ</small><br />
                        {Questions[ptr]['choice' + correct_choice]}</h3>
                </div>

                <LeaderBoardView />
            </div>}

            <QuestionView
                eventEmitter={this.eventEmitter}
                onClick={this.onClick.bind(this)}
                questionTitle={Questions[ptr].question}
                questionid={ptr+1}
                choices={Questions[ptr].choices} />
        </div>
    }
}

export default QuizPage;
