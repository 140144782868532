function generateRandomFiveDigitNumber() {
  // Generate a random number between 10000 and 99999 (inclusive)
  const randomNumber = Math.floor(Math.random() * 900) + 100;
  return randomNumber;
}


function generateRandomName() {
    const adjectives = [
      "Player A",
      "Player B",
      "Player C",
      "Player D",
      "Player E",
      "Player F",
      "Player G",
      "Player H",
      "Player I",
      "Player J",
      "Player K",
      "Player L",
      "Player M"
    ];
  
    const nouns = [
      "unicorn",
      "penguin",
      "giraffe",
      "dragon",
      "phoenix",
      "mermaid",
      "wizard",
      "squirrel",
      "tiger",
      "duckman",
      "elephant",
      "jack",
      "panda",
      "burger",
      "ma",
      "elon",
      "shiba",
      "tesla",
      "toyota",
      "bmw",
      "porsche"
    ];
  
    const adjectiveIndex = Math.floor(Math.random() * adjectives.length);
    const nounIndex = Math.floor(Math.random() * nouns.length);
  
    const adjective = adjectives[adjectiveIndex];
    const noun = nouns[nounIndex];
  
    const randomFiveDigitNumber = generateRandomFiveDigitNumber();

    const randomName = `${adjective}${randomFiveDigitNumber}`;


    return randomName;
  }
  

export default generateRandomName;
