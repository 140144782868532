import './App.css';
import React from 'react';

class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {};
  }

  render(){
    return <header className="App-header">
        <div className='progress_bar_bg'>
          <div className='progress_bar' style={{
            width: this.props.progress*100 + '%'
          }}></div>
        </div>
        {/* LOGO HERE */}
        {/* <img src={"/jurhoot.png"} className="App-logo" alt="logo" /> */}
        <span className='logo'>#PTtothemoon</span>
    </header>
  }
}

export default Header;
