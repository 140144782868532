import './App.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';
import generateRandomName from './RandomNameGen';

class WelcomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: generateRandomName()
        };
    }

    join() {
        window.localStorage.setItem("username", this.state.username);
        window.location.href = "/quiz";
    }

    getExistingUsername() {
        return window.localStorage.getItem("username")
    }

    returnToGame() {
        window.location.href = "/quiz";
    }
    render() {
        return <div className="App">
            <Header progress={1} />
            <h3>Your Display Name</h3>
            <div>
                <input type="text" value={this.state.username} onChange={(e) => {
                    this.setState({
                        username: e.target.value
                    })
                }} className='display-name'></input>
            </div>
            <div className="choices">
                <div onClick={this.join.bind(this)} className='box'>
                    Join Game
                </div>
                {this.getExistingUsername() && <div onClick={this.returnToGame.bind(this)} className='bt-light'>
                    Return to game as {this.getExistingUsername()}
                </div>}
            </div>
        </div>
    }
}

export default WelcomePage;
