import './App.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';
class GameMasterPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gamestate: 'idle',
            current_question: 1,
            answersCt: 0,
            playersCt: 0
        };
    }

    async componentDidMount() {
        let res = await axios.get('/api/last-session');
        this.setState({
            current_question: res.data.current_question,
            gamestate: res.data.gamestate
        })
        this.getPlayers();
        this.getAnswers();
        setInterval(()=>{
            this.getPlayers();
            this.getAnswers();
        }, 4000)
    }

    async getPlayers(){
        let res = await axios.get('/api/scores');
        this.setState({
            playersCt: (res.data||[]).length
        })
    }

    async getAnswers(){
        let res = await axios.get('/api/admin/answersrecv');
        this.setState({
            answersCt: (res.data||[]).length
        })
    }

    async goNext(){
        let resout = await axios.post('/api/admin/qnext');
        this.setState({
            current_question: resout.data.current_question,
            gamestate: resout.data.gamestate
        }, ()=>{
            this.getPlayers();
            this.getAnswers();
        })
    }

    async goPrev(){
        let resout = await axios.post('/api/admin/qprev');
        this.setState({
            current_question: resout.data.current_question,
            gamestate: resout.data.gamestate
        }, ()=>{
            this.getPlayers();
            this.getAnswers();
        })
    }

    render() {
        return <div>
            <Header progress={1} />
           

            <table cellSpacing={0} className='gmc-table'>
               <tbody>
                <tr>
                        <td className='titlebg' colSpan={2}> <h3>Game Master Admin</h3></td>
                    </tr>
                    <tr>
                        <td>Player Count</td>
                        <td>{this.state.playersCt}</td>
                    </tr>
                    <tr>
                        <td>Answers Received</td>
                        <td>{this.state.answersCt}</td>
                    </tr>
               </tbody>
            </table>

            <br/>
            <div className='joystick'>
                <small>CURRENT QUESTION</small>
                <table style={{margin: 'auto'}}>
                    
                    <tr>
                        <td><a onClick={this.goPrev.bind(this)}  className='btnlight big'>&larr;</a></td>
                            <td><h2>{this.state.current_question} <span className='text-purp'>{this.state.gamestate.substring(0,4)}</span></h2></td>
                    <td><a onClick={this.goNext.bind(this)} className='btnlight big'>
                        &rarr;
                        
                        </a></td>
                    </tr>
                </table>
            </div>
        </div>
    }
}

export default GameMasterPage;
