import './App.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';
import generateRandomName from './RandomNameGen';

class LeaderBoardView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            players: []
        };
    }

    async componentDidMount() {
        const q = this.state.q_ptr + 1;
        let res = await axios.get('/api/scores');
        let players = res.data.sort((p2,p1)=>{
            return Number(p1.total) - Number(p2.total)
        })
        this.setState({
            players
        })
    }

    getUsername(){
        return window.localStorage.getItem("username");
    }

    render() {
        return <div>
            <h4 className='animate__flipInX animate__animated animate__delay-1s'>Leaderboard</h4>
            <table className='mt3 leaderboard-table'>
                {this.state.players.map((player, j) => {
                    return <tr className={'animate__flipInX animate__animated animate__delay-1s'}>
                        <td>{j+1}</td>
                        <td><strong>{player.username}</strong> {player.username == this.getUsername() && <small className='you'>YOU</small>}</td>
                        <td className='you'>{player.total} ★</td>
                    </tr>
                })}
                {/* <tr className='animate__fadeIn animate__animated animate__delay-1s'>
                <td>2</td>
                <td><strong>Eart</strong></td>
                <td>105</td>
            </tr>
            <tr className='animate__fadeIn animate__animated animate__delay-2s'>
                <td>3</td>
                <td><strong>xmikado</strong></td>
                <td>50</td>
            </tr> */}
            </table>

        </div>
    }
}

export default LeaderBoardView;
