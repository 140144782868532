import './App.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';

class QuestionView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            choiceColors: ['#FF0000', '#00B050', '#FFC000', '#0070C0'],
            selectedIndex: null
        };
        this.eventEmitter = props.eventEmitter;
        this.eventEmitter.on('questionChanged', ()=>{
            this.setState({
                selectedIndex: null
            })
        })
    }
    
    onClickChoice(i){
        this.props.onClick(i)
        this.setState({
            selectedIndex: i
        })
    }

    render() {
        let selectedIndex = this.state.selectedIndex;

        return <div className="App">
            <span className='question-text'>
                {this.props.questionTitle}
            </span>

            <div className='crispyfish'>
                <img src={"/preview/" + this.props.questionid  + ".jpg"} width={250}></img>
            </div>

            <div className="choices">
                {this.props.choices.map((text, i) => {
                    return <div onClick={this.onClickChoice.bind(this, i)} key={i} style={{
                        backgroundColor: this.state.choiceColors[i]
                    }} className={"box " + (selectedIndex == i ? "selected animate__animated animate__infinite	infinite animate__pulse": "")}>{text}</div>
                })}
            </div>

        </div>
    }
}

export default QuestionView;
