import './App.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';

class ProjectorPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className='container-fluid'>
            <Header progress={1} />
            <div className='row'>
                <div className='col'>
                    <h2 className='logo'>Join game</h2>
                   <div className='container pt-2'>
                     <img src="/qrcode.png" width={250}></img>
                     <br/>
                     <h1>www.jurgun.com</h1>
                   </div>
                </div>
                <div className='col'>
                   <div className='container pt-2'>
                        <iframe style={{border: '10px solid black', borderRadius:10, boxShadow: '0px 100px 50px rgba(0,0,0,0.2)'}} height={600} width={355} src="/quiz?spectator=1"></iframe>
                   </div>
                </div>
            </div>
        </div>
    }
}

export default ProjectorPage;
