import './App.css';
import 'animate.css';
import React from 'react';
import axios from 'axios';
import Header from './Header';
import WelcomePage from './WelcomePage';
import QuizPage from './QuizPage';
import GMPage from './GameMasterPage';
import ProjectorPage from './ProjectorPage';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    };
  }

  async componentDidMount() {
    
  }

  render() {
    return <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            <WelcomePage />
          </Route>
          <Route exact path="/quiz">
            <QuizPage/>
          </Route>
          <Route exact path="/gm">
            <GMPage/>
          </Route>

          <Route exact path="/projector">
            <ProjectorPage/>
          </Route>
          
        </Switch>
      </Router>

    </div>
  }
}

export default App;
